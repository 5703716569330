var x = document.getElementById("myTopnav");

function mobileNav() {
    if (x.className === "topNav") {
        x.className += " responsive";
    } else {
        x.className = "topNav";
    }
}

// Toggle Service Details Popups
var detailsPopup1 = document.getElementById("detailsPopup1");
var serviceh1 = document.getElementById("serviceHeader1");
var serviceBG1 = document.getElementById("serviceBG1");

var detailsPopup2 = document.getElementById("detailsPopup2");
var serviceh2 = document.getElementById("serviceHeader2");
var serviceBG2 = document.getElementById("serviceBG2");

var detailsPopup3 = document.getElementById("detailsPopup3");
var serviceh3 = document.getElementById("serviceHeader3");
var serviceBG3 = document.getElementById("serviceBG3");

var detailsPopup4 = document.getElementById("detailsPopup4");
var serviceh4 = document.getElementById("serviceHeader4");
var serviceBG4 = document.getElementById("serviceBG4");

var detailsPopup5 = document.getElementById("detailsPopup5");
var serviceh5 = document.getElementById("serviceHeader5");
var serviceBG5 = document.getElementById("serviceBG5");

var detailsPopup6 = document.getElementById("detailsPopup6");
var serviceh6 = document.getElementById("serviceHeader6");
var serviceBG6 = document.getElementById("serviceBG6");

var detailsPopup7 = document.getElementById("detailsPopup7");
var serviceh7 = document.getElementById("serviceHeader7");
var serviceBG7 = document.getElementById("serviceBG7");

var detailsPopup8 = document.getElementById("detailsPopup8");
var serviceh8 = document.getElementById("serviceHeader8");
var serviceBG8 = document.getElementById("serviceBG8");

var detailsPopup9 = document.getElementById("detailsPopup9");
var serviceh9 = document.getElementById("serviceHeader9");
var serviceBG9 = document.getElementById("serviceBG9");


function toggleServicePopup1() {
    detailsPopup1.classList.add("detailsPopupShow");
    serviceh1.classList.add("black");
    serviceBG1.classList.add("color");
}    
function toggleServicePopupHide1() {
    detailsPopup1.classList.remove("detailsPopupShow");
    serviceh1.classList.remove("black");
    serviceBG1.classList.remove("color");
}    

function toggleServicePopup2() {
    detailsPopup2.classList.add("detailsPopupShow");
    serviceh2.classList.add("black");
    serviceBG2.classList.add("color");
}    
function toggleServicePopupHide2() {
    detailsPopup2.classList.remove("detailsPopupShow");
    serviceh2.classList.remove("black");
    serviceBG2.classList.remove("color");
}    

function toggleServicePopup3() {
    detailsPopup3.classList.add("detailsPopupShow");
    serviceh3.classList.add("black");
    serviceBG3.classList.add("color");
}    
function toggleServicePopupHide3() {
    detailsPopup3.classList.remove("detailsPopupShow");
    serviceh3.classList.remove("black");
    serviceBG3.classList.remove("color");
}    

function toggleServicePopup4() {
    detailsPopup4.classList.add("detailsPopupShow");
    serviceh4.classList.add("black");
    serviceBG4.classList.add("color");
}    
function toggleServicePopupHide4() {
    detailsPopup4.classList.remove("detailsPopupShow");
    serviceh4.classList.remove("black");
    serviceBG4.classList.remove("color");
}    

function toggleServicePopup5() {
    detailsPopup5.classList.add("detailsPopupShow");
    serviceh5.classList.add("black");
    serviceBG5.classList.add("color");
}    
function toggleServicePopupHide5() {
    detailsPopup5.classList.remove("detailsPopupShow");
    serviceh5.classList.remove("black");
    serviceBG5.classList.remove("color");
}    

function toggleServicePopup6() {
    detailsPopup6.classList.add("detailsPopupShow");
    serviceh6.classList.add("black");
    serviceBG6.classList.add("color");
}    
function toggleServicePopupHide6() {
    detailsPopup6.classList.remove("detailsPopupShow");
    serviceh6.classList.remove("black");
    serviceBG6.classList.remove("color");
}    

function toggleServicePopup7() {
    detailsPopup7.classList.add("detailsPopupShow");
    serviceh7.classList.add("black");
    serviceBG7.classList.add("color");
}    
function toggleServicePopupHide7() {
    detailsPopup7.classList.remove("detailsPopupShow");
    serviceh7.classList.remove("black");
    serviceBG7.classList.remove("color");
}    

function toggleServicePopup8() {
    detailsPopup8.classList.add("detailsPopupShow");
    serviceh8.classList.add("black");
    serviceBG8.classList.add("color");
}    
function toggleServicePopupHide8() {
    detailsPopup8.classList.remove("detailsPopupShow");
    serviceh8.classList.remove("black");
    serviceBG8.classList.remove("color");
}    

function toggleServicePopup9() {
    detailsPopup9.classList.add("detailsPopupShow");
    serviceh9.classList.add("black");
    serviceBG9.classList.add("color");
}    
function toggleServicePopupHide9() {
    detailsPopup9.classList.remove("detailsPopupShow");
    serviceh9.classList.remove("black");
    serviceBG9.classList.remove("color");
}    

